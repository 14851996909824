<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmChatBotDataSrcFormData && Object.keys(vmChatBotDataSrcFormData).length > 0">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationfile_type">
      {{cvFileTypeLabel}}</label
    >
    <input
      v-model="vmChatBotDataSrcFormData.file_type"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationfile_name">
      {{cvFileNameLabel}}</label
    >
    <input
      v-model="vmChatBotDataSrcFormData.file_name"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationfile_extension">
      {{cvFileExtensionLabel}}</label
    >
    <input
      v-model="vmChatBotDataSrcFormData.file_extension"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationdata_schema">
      {{cvDataSchemaLabel}}</label
    >
    <input
      v-model="vmChatBotDataSrcFormData.data_schema"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationurl">
      {{cvUrlLabel}}</label
    >
    <input
      v-model="vmChatBotDataSrcFormData.url"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationreplace_keywords">
      {{cvReplaceKeywordsLabel}}</label
    >
    <input
      v-model="vmChatBotDataSrcFormData.replace_keywords"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="chatbot_data_srcEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { ChatBotDataSrcs } from "../../../FackApi/api/ChatBotDataSrc"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "ChatBotDataSrcEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propChatBotDataSrcObj: {
      type: Object,
      default: function () {
        return {
          "file_type": "",
          "file_name": "",
          "file_extension": "",
          "data_schema": "",
          "url": "",
          "replace_keywords": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit chatbot_data_src",
      cvCardSubHeader: "Edit chatbot_data_src sub header",
      cvSubmitBtn: "Edit",
      cvFileTypeLabel: "file type",
      cvFileNameLabel: "file name",
      cvFileExtensionLabel: "file extension",
      cvDataSchemaLabel: "data schema",
      cvUrlLabel: "url",
      cvReplaceKeywordsLabel: "replace keywords",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "ChatBotDataSrc Updated",
      vmChatBotDataSrcFormData: {}
    }
  },
  mounted () {
    socialvue.index()
    this.chatbot_data_srcView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmChatBotDataSrcFormData) {
          if (!this.vmChatBotDataSrcFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * chatbot_data_srcView
     */
    async chatbot_data_srcView () {
      try {
        if (this.propOpenedInModal) {
          this.vmChatBotDataSrcFormData = this.propChatBotDataSrcObj
        }
        else {
          let chatbotDataSrcId = this.$route.params.cbds_id
          let chatbotDataSrcViewResp = await ChatBotDataSrcs.chatbot_data_srcView(this, chatbotDataSrcId)

          if (chatbotDataSrcViewResp && chatbotDataSrcViewResp.resp_status) {
            this.vmChatBotDataSrcFormData = chatbotDataSrcViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_data_srcView() and Exception:", err.message)
      }
    },
    /**
     * chatbot_data_srcEdit
     */
    async chatbot_data_srcEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let chatbotDataSrcAddResp = await ChatBotDataSrcs.chatbot_data_srcEdit(this, this.vmChatBotDataSrcFormData)
        await ApiResponse.responseMessageDisplay(this, chatbotDataSrcAddResp)

        if (chatbotDataSrcAddResp && !chatbotDataSrcAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseChatBotDataSrcEditModal", this.vmChatBotDataSrcFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_data_srcEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
